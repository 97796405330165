<template>
  <div>
    <v-icon small class="mr-2" @click="showLiquidationDetail()">
      mdi-eye
    </v-icon>
    <v-dialog
      v-model="dialogShowLiquidationDetail"
      fullscreen
      hide-overlay
      persistent
      transition="dialog-bottom-transition"
      scrollable
    >
      <v-card tile>
        <v-toolbar flat dark color="primary" style="flex: none">
          <v-btn icon dark @click="closeDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title
            >ID: {{ liquidation.idLiquidacion }} --- Jobber:
            {{ liquidation.nombreJobber }} --- Fecha: {{ fechaCreacion }} ---
            Estado: {{ liquidation.estadoLiquidacion }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text class="pt-2 bg-default">
          <v-row>
            <v-col cols="12">
              <Table
                :header="headerLiquidation"
                :data="liquidationDetail"
                tableName="Detalle de Liquidación"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {
  GET_LIQUIDATION_DETAIL,
  GET_LIQUIDATION,
} from "@/core/services/store/liquidation.module";
import KTUtil from "@/assets/js/components/util";
import moment from "moment";
import Table from "../globals/Table.vue";
export default {
  name: "LiquidationDetail",
  props: {
    liquidationID: Number,
  },
  components: {
    Table,
  },
  data() {
    return {
      dialogShowLiquidationDetail: false,
      liquidation: {},
      liquidationDetail: [],
      headerLiquidation: [
        {
          text: "ID Liquidación",
          value: "idLiquidacion",
        },
        {
          text: "ID Tarea",
          value: "idTarea",
        },
        {
          text: "Fecha Gestión",
          value: "fechaGestion",
          render: (item) =>
            moment(item.fechaGestion, "YYYY-MM-DD HH:mm:ss").format(
              "DD-MM-YYYY HH:mm:ss"
            ),
        },
        {
          text: "Mandante",
          value: "nombreMandante",
        },
        {
          text: "ID Campaña",
          value: "idCampana",
        },
        {
          text: "Campaña",
          value: "nombreCampana",
        },
        {
          text: "T. Campana",
          value: "tipoCampana",
        },
        {
          text: "Costo",
          value: "costo",
        },
        {
          text: "Reembolso",
          value: "reembolso",
        },
        {
          text: "Gestiones",
          value: "cantidadGestiones",
        },
        {
          text: "Total",
          value: "totalTarea",
        },
        {
          text: "",
          value: "actions",
          components: [
            {
              import: async () => await import("../task/TaskDetail.vue"),
              props: (item) => {
                return { taskID: item.idTarea };
              },
            },
          ],
        },
      ],
    };
  },
  created() {},
  methods: {
    showLiquidationDetail() {
      const oVue = this;
      oVue.getLiquidation();
    },
    getLiquidationDetail() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_LIQUIDATION_DETAIL, { liquidation: oVue.liquidationID })
        .then(({ data: aDetail }) => {
          oVue.liquidationDetail = aDetail;
          oVue.dialogShowLiquidationDetail = true;
          KTUtil.removeLoading();
        });
    },
    getLiquidation() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.$store
        .dispatch(GET_LIQUIDATION, { liquidation: oVue.liquidationID })
        .then(({ data: aLiquidation }) => {
          oVue.liquidation = aLiquidation.length > 0 ? aLiquidation[0] : {};
          oVue.getLiquidationDetail();
          KTUtil.removeLoading();
        });
    },
    closeDialog() {
      const oVue = this;
      oVue.dialogShowLiquidationDetail = false;
      oVue.liquidation = {};
      oVue.liquidationDetail = [];
    },
  },
  computed: {
    fechaCreacion() {
      const oVue = this;
      const sFechaCreacion = oVue.liquidation.fechaCreacion;
      return moment(sFechaCreacion, "YYYY-MM-DD HH:mm:ss").format(
        "DD-MM-YYYY HH:mm:ss"
      );
    },
    getLiquidationDetailData() {
      const oVue = this;
      return oVue.liquidationDetail;
    },
    getHeaderLiquidation() {
      const oVue = this;
      return oVue.headerLiquidation;
    },
  },
  watch: {},
};
</script>
